import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./kb-cover-search.html";
import { CoverSearch } from "./cover-search";
import type { KnowledgeBaseItem } from "../models/KnowledgeBase";

@Component({
	render,
	components: {
		CoverSearch
	}
})
export class KbCoverSearch extends Vue {
	onSelect(item: KnowledgeBaseItem) {
		window.open(item.url);
	}
}
