export interface Person {
	id: string;
	firstname: string;
	lastname: string;
	imageUrl: string;
	functions: PersonFunction[];
	address: Address;
	contacts: PersonContact[];
	description: string;
	subjects: SubjectReference[];
}

export interface PersonListItem {
	id: string;
	firstname: string;
	lastname: string;
	imageUrl: string;
	functions: PersonFunction[];
}

export interface Address {
	addressLine: string;
	postalCode: string;
	locality: string;
}

export interface SubjectReference {
	title: string;
	rescription: string;
	link: string;
}

export interface PersonContact {
	type: ContactType;
	value: string;
}

export interface PersonFunction {
	role: Role;
	roleDisplayName: string;
	description: string;
	weight: number;
}

export enum ContactType {
	PhoneNumber = "PhoneNumber",
	EmailAddress = "EmailAddress",
	WebsiteUrl = "WebsiteUrl",
}

export const ContactTypeNames = new Map<ContactType, string>([
	[ContactType.PhoneNumber, "Telefon"],
	[ContactType.EmailAddress, "E-Mail"],
	[ContactType.WebsiteUrl, "Website"],
]);

export enum Role {
	Management = "Management",
	Administration = "Administration",
	Maintenance = "Maintenance",
	BoosterClub = "BoosterClub",
	SponsoringAssociation = "SponsoringAssociation",
	Teacher = "Teacher",
	TeachersBoard = "TeachersBoard"
}

export const RoleNames = new Map<Role, string>([
	[Role.Management, "Schulleitung"],
	[Role.Administration, "Administration"],
	[Role.Maintenance, "Unterhalt"],
	[Role.BoosterClub, "Förderverein"],
	[Role.SponsoringAssociation, "Trägerverein"],
	[Role.Teacher, "Lehrperson"],
	[Role.TeachersBoard, "Lehrerkonvent"]
]);
