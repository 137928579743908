import Flickity from "flickity";
import imagesLoaded from "imagesloaded";
import enquire from "enquire.js";
import { Fancybox } from "@fancyapps/ui";
import $ from "jquery";

import { createSpinner, mediaQueries } from "./utils";
import { PersonDetail } from "./vue/person-detail";

export class Site {
	constructor() {
		$(".asset.is-thumbnail").click(function () {
			if (this.closest(".asset-list") != null) {
				return;
			}

			new Fancybox([{
				type: "image",
				src: this.getAttribute("data-zoom-src")!,
				width: 1800,
				height: 1300
			}], {
			});
		});

		$(".asset-list.is-thumbnail .asset").click(function () {
			let siblings = Array.from(this.closest(".asset-list")?.querySelectorAll<HTMLElement>(".asset")!);
			let fancybox = new Fancybox(
				siblings.map(e => {
					return <any>{
						type: "image",
						src: e.getAttribute("data-zoom-src"),
						width: 1800,
						height: 1300
					};
				}), {
			});
			fancybox.jumpTo(siblings.indexOf(this));
		});

		$(".box-person .person-list > li").click((e) => {
			var item = $(e.currentTarget);
			PersonDetail.open(item.data("id"));
		});

		if ($(".subject-gallery").length) {
			imagesLoaded($(".subject-gallery-content")[0], () => {
				var flickity = new Flickity($(".subject-gallery-content")[0], {
					autoPlay: true,
					cellAlign: "left",
					contain: true,
					pageDots: false
				});

				flickity.on("staticClick", function (event, pointer, cellElement, cellIndex) {
					let image = cellElement?.querySelector<HTMLElement>("img");
					if (image != null) {
						new Fancybox([{
							type: "image",
							src: image.getAttribute("data-zoom-src")!,
							width: 1800,
							height: 1300
						}], {
						});
					}
				});
			});
		}

		this.setupBoxes();
		this.setupNavigation();
	}

	setupBoxes() {
		$(".box-collapsible.is-open .box-header > .tcon").addClass("tcon-transform");
		$(".box-collapsible .box-header").click(function (e) {
			var box = $(e.currentTarget).parent();
			var contentNotLoaded = box.hasClass("box-ajax-load");

			if (box.hasClass("is-open")) {
				box.removeClass("is-open");
				box.trigger("collapse");
			} else {
				if (!contentNotLoaded) {
					box.addClass("is-open");
				}
				box.trigger("expand");
				box[0].dispatchEvent(new CustomEvent("expand"));
			}

			box.find(".box-header > .tcon").toggleClass("tcon-transform");
		});
		$(".box-header a").click(function (e) {
			e.preventDefault();
		});
	}

	setupNavigation() {
		enquire.register(mediaQueries.desktopMenu, {
			match: () => {
				$("header").css({ height: "" });
				$(".navigation-primary").removeClass("is-shifted");
				$(".nav-node").removeClass("is-open");
			}
		});

		enquire.register(mediaQueries.mobileMenu, {
			match: () => {
				$(".nav-menu").css({ display: "" });
				$(".nav-node").removeClass("is-open");
				$("header:has(.nav-main.is-open)").css({ height: "100%" });
			}
		});

		$(".nav-main")
			.on("expand", () => {
				document.querySelector<HTMLElement>("header")!.style.height = "100%";
			})
			.on("collapse", () => {
				document.querySelector<HTMLElement>("header")!.style.height = "3em";
			});

		$(".nav-main .nav-node.nav-level-1 > a").on("click", e => {
			var menuItem = $(e.currentTarget).parent();
			var submenu = menuItem.children(".nav-menu");

			if (submenu.length) {
				if (!menuItem.hasClass("is-open")) {
					if (window.matchMedia(mediaQueries.desktopMenu).matches) {
						this.openDesktopSubmenu(menuItem, e.target);
					} else {
						this.openMobileSubmenu(menuItem);
					}
				}

				e.preventDefault();
			}
		});

		$(".nav-back-controller").click(function (e) {
			$(".navigation-primary").removeClass("is-shifted");
			$(this).parent().removeClass("is-open");
		});

	}

	openMobileSubmenu(menuItem: JQuery) {
		$(".navigation-primary").addClass("is-shifted");
		menuItem.addClass("is-open");
	}

	openDesktopSubmenu(menuItem: JQuery, link: Element) {
		var trigger = menuItem.children("a");

		var openMenuItem = $(".nav-main .nav-node.is-open");
		if (openMenuItem.length) {
			this.closeDesktopSubmenu(openMenuItem);
		}

		menuItem.addClass("is-open");
		trigger.children(".nav-dropdown-controller").addClass("tcon-transform");

		$(document).on("click.closemenu", (e) => {
			if (!$(e.target).closest(menuItem).length && <any>e.target != link) {
				this.closeDesktopSubmenu(menuItem);
			}
		});

		trigger.on("click.closemenu", (e) => {
			this.closeDesktopSubmenu(menuItem);
		});
	}
	closeDesktopSubmenu(menuItem: JQuery) {
		var trigger = menuItem.children("a");

		$(document).off("click.closemenu");
		trigger.off("click.closemenu");

		trigger.children(".nav-dropdown-controller").removeClass("tcon-transform");
		menuItem.removeClass("is-open");
	}
}
