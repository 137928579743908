<div class="person-detail-wrapper">
	<span class="tcon tcon-menu--arrow tcon-transform btn-person-detail-close">
		<span class="tcon-menu__lines"></span>
	</span>
	<div class="person-detail">
		<div class="person-portrait">
			<img class="portrait" width="800" height="540" :src="`${person.imageUrl}?width=800&height=540`" />
		</div>
		<div class="person-text">
			<h1>{{person.firstname}} {{person.lastname}}</h1>
			<div class="columns">
				<div class="column column-subjects" v-if="person.subjects && person.subjects.length > 0">
					<h3>Fächer</h3>
					<ul class="styled flush">
						<li v-for="subject in person.subjects">
							<a :href="subject.url">{{subject.title}}</a>
						</li>
					</ul>
				</div>
				<div class="column column-functions">
					<h3>Funktionen</h3>
					<ul class="styled flush">
						<li v-for="func in person.functions">{{func.description}}</li>
					</ul>
				</div>
			</div>

			<h3>Kontakt</h3>
			<div class="columns">
				<div class="column">
					<template v-for="contact in person.contacts">
						<small class="contact-label">{{contactTypeNames.get(contact.type)}}</small>
						<a :href="formatContactLink(contact)" target="_blank">{{contact.value}}</a>
						<br />
					</template>
				</div>
			</div>
		</div>
	</div>
</div>
