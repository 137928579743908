import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";
import debounce from "lodash/debounce";

import { render } from "./cover-search.html";

@Component({
	render,
	emits: ["select"],
})
export class CoverSearch extends Vue {
	private input: HTMLElement;

	isLoading: boolean = false;
	results: any[] = [];

	@Prop()
	url: string;

	@Prop()
	placeholder: string;

	@Prop()
	classes: string;

	searchDebounced = debounce(this.search, 200);
	async search(query: string) {
		let url = this.url.replace("%QUERY", query);
		try {
			this.isLoading = true;
			let response = await fetch(url, { method: "GET", headers: { "Accept": "application/json" } });
			if (response.ok) {
				let data = await response.json();
				this.results = data.items;
			} else {
				this.results = [];
			}
		} catch (e) {
		} finally {
			this.isLoading = false;
		}
	}

	onSelect(item: any) {
		this.$emit("select", item);
	}
}
