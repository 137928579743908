import { Vue, Prop, Component } from "@wagich/vue-facing-decorator-metadata";
import Flickity from "flickity";
import imagesLoaded from "imagesloaded";
import { format as formatDate } from "date-fns";
import deLocale from "date-fns/locale/de";

import SlideYUpTransition from "../transitions/slide-y-up-transition.vue";
import { render } from "./news-list.html";
import { createSpinner, JsonHeaders } from "../utils";
import { type NewsModel, NewsModelKind } from "../models/NewsModel";
import { type ListModel } from "../models/ListModel";
import { NewsDetail } from "./news-detail";
import { trackEvent } from "../analytics";

@Component({
	render,
	components: {
		SlideYUpTransition
	},
})
export class NewsList extends Vue {
	news: NewsModel[] = [];

	@Prop()
	url: string;

	mounted() {
		document.querySelector(".common-news")?.addEventListener("expand", () => this.load(), { once: true });
	}

	async load() {
		let spinner = createSpinner().spin(this.$el as HTMLElement);
		let response = await fetch(this.url, { headers: JsonHeaders });
		spinner.stop();
		if (response.status >= 300) {
			return;
		}
		let data: ListModel<NewsModel> = await response.json();
		this.news = data.items;

		trackEvent("Aktuell ausgeklappt");

		imagesLoaded(this.$el, () => {
			let flickity = new Flickity(<Element>this.$refs.flickity, {
				cellAlign: "left",
				contain: true,
				prevNextButtons: true,
				setGallerySize: true
			});
		});
	}

	getIsSnippetShortened(item: NewsModel): boolean {
		if (item.snippetHtml && item.textHtml) {
			return item.snippetHtml.length < item.textHtml.length;
		}
		return false;
	}

	getHasExternalLink(item: NewsModel): boolean {
		return item.kind == NewsModelKind.Event && !!item.url && !item.url.startsWith("/");
	}

	openItem(item: NewsModel) {
		NewsDetail.open(item);
	}
}
