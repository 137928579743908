import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";
import $ from "jquery";
import { createApp } from "vue";
import { Fancybox } from "@fancyapps/ui";

import { render } from "./news-detail.html";
import { createSpinner } from "../utils";
import type { NewsModel } from "../models/NewsModel";

@Component({
	render
})
export class NewsDetail extends Vue {
	@Prop()
	news: NewsModel;

	static async open(news: NewsModel) {
		let wrapper = document.createElement("div");
		let element = document.createElement("div");
		wrapper.appendChild(element);
		let detail = createApp(NewsDetail, { news }).mount(element);

		new Fancybox([
			{
				type: "inline",
				src: wrapper,
			}],
			{
				mainClass: "fancybox-width-limited",
			});
	}
}
