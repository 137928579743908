<div class="calendar-items">
	<slide-y-up-transition group>
		<div class="calendar-item" :key="getKey(event)" v-for="event in events">
			<a class="calendar-item-show" :href="event.url">
				<h2 class="calendar-item-title">{{ $formatters.date(event.dateTime, 'EEEE, d. MMMM') }}</h2>
				<div class="calendar-item-description">
					<strong>{{ event.name }}</strong>
					{{event.description}}
				</div>
				<em class="calendar-item-date">{{ $formatters.date(event.dateTime, 'H.mm') }} Uhr, {{ event.location }}</em>
			</a>
		</div>
	</slide-y-up-transition>
</div>
