import { Vue, Prop, Component, Watch } from "@wagich/vue-facing-decorator-metadata";
import imagesLoaded from "imagesloaded";
import $ from "jquery";

import { render } from "./team-filter-card.html";
import type { Person, PersonListItem } from "../models/Person";
import { PersonDetail } from "./person-detail";
import { createSpinner } from "../utils";
import { eventBus } from "../event-bus";
import { createApp } from "vue";

export const OpenTeamFilterCardEvent = "OpenTeamFilterCardEvent";
export interface OpenTeamFilterCardEventArgs {
	personId: string;
}


@Component({
	render,
	components: {
		PersonDetail
	}
})
export class TeamFilterCard extends Vue {
	private detailElement: Element | null;

	@Prop()
	person: PersonListItem;

	personDetail: Person | null = null;
	isActive: boolean = false;

	get anchorStyle(): string {
		return `background-image:linear-gradient(to bottom, rgba(11,32,45,0) 70%, rgba(11,32,45,1) 100%), url('${this.person.imageUrl}?width=350&height=350')`;
	}

	mounted() {
		eventBus.on(OpenTeamFilterCardEvent, (e: OpenTeamFilterCardEventArgs) => {
			if (e.personId !== this.person.id) {
				this.closeDetail();
			}
		});
	}

	toggleDetail(e: Event) {
		if (this.isActive) {
			this.closeDetail();
		} else {
			this.openDetail();
			e.stopPropagation();
		}
	}

	async openDetail() {
		if (this.personDetail == null) {
			let spinner = createSpinner().spin(this.$el as HTMLElement);
			let response = await fetch(`/api/persons/${this.person.id}`);
			if (response.status >= 300) {
				spinner.stop();
				return;
			}

			await new Promise<void>(resolve => {
				imagesLoaded(this.$el, () => {
					spinner.stop();
					resolve();
				});
			});
			this.personDetail = await response.json();
		}

		let element = document.createElement("div");
		let detail = createApp(PersonDetail, { person: this.personDetail }).mount(element);
		this.detailElement = detail.$el;
		$(this.$el).after(detail.$el);
		this.detailElement!.classList.add("is-hidden");

		document.addEventListener("click", this.documentClick);
		$(document).one("click", ".btn-person-detail-close", () => this.closeDetail());

		this.showDetail();
	}

	showDetail() {
		this.isActive = true;
		this.detailElement!.classList.remove("is-hidden");

		let args: OpenTeamFilterCardEventArgs = { personId: this.person.id };
		eventBus.emit(OpenTeamFilterCardEvent, args);
	}

	closeDetail() {
		this.isActive = false;
		document.removeEventListener("click", this.documentClick);

		if (this.detailElement != null) {
			this.detailElement.classList.add("is-hidden");
			window.setTimeout(() => {
				if (this.detailElement != null) {
					this.detailElement.parentElement!.removeChild(this.detailElement);
					this.detailElement = null;
				}
			}, 400); // css transition takes 400ms
		}
	}

	documentClick(e: Event) {
		if (!$(<HTMLElement>e.target).closest(".person-detail-wrapper").length) {
			this.closeDetail();
		}
	}
}
