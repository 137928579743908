import { Vue, Prop, Component } from "@wagich/vue-facing-decorator-metadata";

import SlideYUpTransition from "../transitions/slide-y-up-transition.vue";
import { render } from "./event-list.html";
import { createSpinner, JsonHeaders } from "../utils";
import { type ListModel } from "../models/ListModel";
import { type EventModel } from "../models/Events";
import { trackEvent } from "../analytics";

@Component({
	render,
	components: {
		SlideYUpTransition
	},
})
export class EventList extends Vue {
	events: EventModel[] = [];
	isLoaded: boolean = false;

	@Prop()
	url: string;

	mounted() {
		document.querySelector(".common-calendar")?.addEventListener("expand", () => this.load(), { once: true });
	}

	getKey(event: EventModel) {
		return `${event.name}_${event.dateTime}_${event.location}`;
	}

	async load() {
		let spinner = createSpinner().spin(this.$el as HTMLElement);
		let response = await fetch(this.url, { headers: JsonHeaders });
		spinner.stop();
		if (response.status >= 300) {
			return;
		}
		let data: ListModel<EventModel> = await response.json();
		this.events = data.items;
		this.isLoaded = true;

		trackEvent("Kalender ausgeklappt");
	}
}
