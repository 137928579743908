import Flickity from "flickity";

export class NewsInfoscreen {
	constructor() {
		let slideContainer = document.querySelector(".news-infoscreen__container");
		if (slideContainer != null) {
			new Flickity(slideContainer, {
				autoPlay: 7000,
				pauseAutoPlayOnHover: false,
				prevNextButtons: false,
				pageDots: false,
				wrapAround: true,
				initialIndex: Math.round(Math.random() * (slideContainer.children.length - 1))
			});
		}
	}
}
