import { Spinner, SpinnerOptions } from "spin.js";

export function createSpinner(options: SpinnerOptions = {}) {
	var defaultOptions = {
		lines: 13,
		length: 15,
		width: 2,
		radius: 20,
		corners: 0,
		speed: 1,
		trail: 50,
		hwaccel: true,
		color: "#a6192e"
	};

	return new Spinner(Object.assign(defaultOptions, options));
}

export const mediaQueries = {
	none: "screen and (max-width: 30em)",
	small: "screen and (min-width: 30em)",
	medium: "screen and (min-width: 60em)",
	fullsize: "screen and (min-width: 90em)",

	timeline: "screen and (min-width: 30em) and (min-height: 30em)",
	mobileMenu: "screen and (max-width: 79.9999999em)",
	desktopMenu: "screen and (min-width: 80em)"
};

export const JsonHeaders = { accept: "application/json" };
