import enquire from "enquire.js";
import debounce from "lodash/debounce";
import ScrollWatcher from "scroll-watcher";
import $ from "jquery";

import { mediaQueries } from "./utils";

export class Cover {
	private mainTitle: JQuery;
	private cover: JQuery;
	private titleFg: JQuery | null = null;
	private titleOffsetLimit = 0;

	constructor() {
		this.mainTitle = $(".main-title");
		this.cover = $(".cover");

		if (this.cover.length) {
			if (this.mainTitle.length && !this.cover.hasClass("no-parallax")) {
				this.titleFg = this.mainTitle
					.clone()
					.appendTo(this.cover)
					.addClass("main-title-fg");

				this.freezeTitleFg();
				$(window).on("resize", debounce(() => this.freezeTitleFg(), 200));
			}

			if (!this.cover.hasClass("no-parallax")) {
				$(window).on("resize", debounce(() => {
					this.titleOffsetLimit = this.getTitleOffsetLimit();
					this.setCoverTransform();
				}, 200));

				$(window).one("scroll", () => {
					this.setCoverTransform();
					$(window).on("scroll", () => this.setCoverTransform());
				});
				this.titleOffsetLimit = this.getTitleOffsetLimit();
			}

			var searchBox = $(".cover-search");
			if (searchBox.length) {
				this.setupSearch(searchBox);
			}
		}

		var arrow = $(".cover-arrow");
		if (arrow.length) {
			let scroll = new ScrollWatcher();
			scroll
				.watch(this.cover[0], 5)
				.on("exit:partial", function () {
					arrow.removeClass("is-visible");
				})
				.on("enter:full", function () {
					arrow.addClass("is-visible");
				})

			$(".cover-arrow").click(() => {
				if (this.mainTitle != null) {
					document.querySelector(".main-title")?.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			});

			$(window).one("scroll", () => arrow.removeClass("is-visible"));
		}
	}

	private setupSearch(searchBox: JQuery) {
		$("header")
			.on("pinned", () => searchBox.addClass("is-header-pinned"))
			.on("unpinned", () => searchBox.removeClass("is-header-pinned"));

		enquire.register(mediaQueries.medium, {
			match: () => {
				searchBox.sticky({
					wrapperClassName: "cover-search",
					getWidthFrom: ".cover-search",
					responsiveWidth: true
				}).removeClass("cover-search");
			},
			unmatch: () => {
				searchBox.unstick().addClass("cover-search");
			}
		});
	}

	freezeTitleFg() {
		if (this.titleFg != null) {
			this.titleFg.css({
				top: this.mainTitle.position().top || 0,
				left: this.mainTitle.position().left || 0,
				width: this.mainTitle.width() || 0,
				"text-align": this.mainTitle.css("text-align")
			});
		}
	}

	getTitleOffsetLimit() {
		return $(window).innerHeight() || 0 * 0.35;
	}

	setCoverTransform() {
		var scrollTop = $(window).scrollTop() || 0;
		var offset = Math.min(scrollTop * 2, this.titleOffsetLimit);

		this.cover.css({ transform: "translate3d(0, " + -offset + "px, 0)" });
		if (this.mainTitle.length) {
			if (this.titleFg != null) {
				this.titleFg.css({ transform: "translate3d(0, " + offset + "px, 0)" });
			}
		}
	}
}
