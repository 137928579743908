import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./person-detail.html";
import { type Person, type PersonContact, ContactType, ContactTypeNames, Role, RoleNames } from "../models/Person";
import { createSpinner } from "../utils";
import { Fancybox } from "@fancyapps/ui";
import { createApp, type App } from "vue";

const contactLinkFormatters = new Map<ContactType, (contact: PersonContact) => void>([
	[ContactType.PhoneNumber, contact => {
		var normalizedNumber = contact.value.replace(/\s/g, "");
		return `tel:${normalizedNumber}`;
	}],
	[ContactType.EmailAddress, contact => `mailto:${contact.value}`]
]);

@Component({
	render
})
export class PersonDetail extends Vue {
	contactTypeNames = ContactTypeNames;
	roleNames = RoleNames;

	@Prop()
	person: Person;

	formatContactLink(contact: PersonContact) {
		let formatter = contactLinkFormatters.get(contact.type);
		if (formatter != null) {
			return formatter(contact);
		}
		return contact.value;
	}

	static async open(id: string) {
		var spinner = createSpinner().spin(document.body);
		let response = await fetch(`/api/persons/${id}`);
		spinner.stop();
		if (response.status >= 300) {
			return;
		}
		let data = await response.json();

		let wrapper = document.createElement("div");
		let element = document.createElement("div");
		wrapper.appendChild(element);
		
		createApp(PersonDetail, { person: data }).mount(element);

		new Fancybox([
			{
				type: "inline",
				src: wrapper
			}
		], {
			on: {
				loaded: function (instance: any, current: any) {
					current.width = Math.min(window.innerWidth * 0.9, 1280);
				}
			}
		});
	}
}
