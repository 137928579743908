<cover-search url="/api/kb?q=%QUERY" placeholder="Suchen nach…" @select="onSelect">
	<template v-slot="{item}">
		<div class="aa-suggestion">
			<h5>{{item.title}}</h5>
			{{item.description}}
			<span class="search-result-download">
				<svg-icon name="download" />
				Download {{item.type}}
			</span>
		</div>
	</template>
	<template #empty>
		<p>Keine passenden Dokumente gefunden.</p>
	</template>
</cover-search>
