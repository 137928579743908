<div class="card" :class="{'is-active': isActive}" @click="toggleDetail">
	<a :href="person.link" :style="anchorStyle">
		<div class="card-text">
			<h3>{{person.firstname}} {{person.lastname}}</h3>
			<ul class="extended-info">
				<li v-for="func in person.functions">{{func.description}}</li>
			</ul>
		</div>
	</a>
</div>
