import { Vue, Prop, Component } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./team-filter.html";
import { createSpinner } from "../utils";
import { TeamFilterCard } from "./team-filter-card";
import { type Person, Role, type PersonListItem } from "../models/Person";
import { type ListModel } from "../models/ListModel";


@Component({
render,
	components: {
		TeamFilterCard
	}
})
export class TeamFilter extends Vue {
	persons: PersonListItem[] = [];
	activeCategory: Role | null = null;

	get filteredPersons(): PersonListItem[] {
		return this.persons.filter(p => p.functions.filter(f => f.role == this.activeCategory).length > 0);
	}

	mounted() {
		this.load();
	}

	async load() {
		let spinner = createSpinner().spin(this.$el as HTMLElement);
		let response = await fetch("/api/persons");
		spinner.stop();
		if (response.status >= 300) {
			return;
		}

		let data: ListModel<PersonListItem> = await response.json();
		this.persons = data.items;
	}
}
