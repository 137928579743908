export interface NewsModel {
	kind: NewsModelKind;
	title: string;
	snippetHtml: string;
	textHtml: string;
	dateTime: string;
	location?: string;
	url?: string;
	imageUrl?: string;
	imageOrientation: "portrait" | "landscape";
}

export enum NewsModelKind {
	News = "News",
	Event = "Event"
}

