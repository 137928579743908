<cover-search url="/api/subjects?q=%QUERY" placeholder="Suchen nach Fach, Gruppe oder Instrument…" @select="onSelect">
	<template v-slot="{item}">
		<div class="aa-suggestion">
			<h5>{{item.name}}</h5>
			{{item.description}}
		</div>
	</template>
	<template #empty>
		<p>Keine passenden Angebote gefunden.</p>
	</template>
</cover-search>
