<template>
	<component :is="componentType"
			   :tag="tag"
			   type="animation"
			   v-bind="$attrs"
			   v-on="hooks"
			   enter-active-class="slideYIn"
			   move-class="slide-move"
			   leave-active-class="slideYOut">
		<slot></slot>
	</component>
</template>
<script>
	import baseTransition from "./base-transition";

	export default {
		name: 'slide-y-up-transition',
		mixins: [baseTransition],
		props: {
			styles: {
				type: Object,
				default: () => {
					return {
						animationFillMode: 'both',
						animationTimingFunction: 'cubic-bezier(.25,.8,.50,1)'
					}
				}
			}
		}
	}
</script>
