<div class="card-list-container" :class="{'is-filter-active': activeCategory != null}">
	<div class="animationWrapper has-filters">
		<div class="card-filters">
			<label class="card-filter" :class="{'is-active': activeCategory == 'teacher'}">
				<input type="radio" value="teacher" v-model="activeCategory" />
				<span>Lehrpersonen</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
			<label class="card-filter" :class="{'is-active': activeCategory == 'management'}">
				<input type="radio" value="management" v-model="activeCategory" />
				<span>Schulleitung</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
			<label class="card-filter" :class="{'is-active': activeCategory == 'teachersBoard'}">
				<input type="radio" value="teachersBoard" v-model="activeCategory" />
				<span>Vorstand Lehrerkonvent</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
			<label class="card-filter" :class="{'is-active': activeCategory == 'administration'}">
				<input type="radio" value="administration" v-model="activeCategory" />
				<span>Administration</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
			<label class="card-filter" :class="{'is-active': activeCategory == 'boosterClub'}">
				<input type="radio" value="boosterClub" v-model="activeCategory" />
				<span>Vorstand Förderverein</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
			<label class="card-filter" :class="{'is-active': activeCategory == 'sponsoringAssociation'}">
				<input type="radio" value="sponsoringAssociation" v-model="activeCategory" />
				<span>Vorstand Trägerverein</span>
				<span class="tcon tcon-menu--arrow">
					<span class="tcon-menu__lines"></span>
				</span>
			</label>
		</div>

		<button class="button is-link btn-filter-back" @click="activeCategory = null">‹ zurück</button>

		<transition-group class="card-list clearfix" tag="div" name="fade">
			<team-filter-card :person="person" :key="person.id" v-for="person in filteredPersons" />
		</transition-group>
	</div>
</div>
