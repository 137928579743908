import "../Styles/index.scss";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { initializeMaps } from "@wagich/bluc-map";
import { ConfigProgrammatic, Button, Field, Input, Select, Notification, Modal, Snackbar, Checkbox, Radio, Loading, Autocomplete } from "buefy";
import humanize from "humanize-plus";
import { format as formatDate } from "date-fns";
import deLocale from "date-fns/locale/de";
import $ from "jquery";
import "./vendor/jquery-sticky";

import "./plyr";
import { Cover } from "./cover";
import { Events } from "./events";
import { Site } from "./site";
import { NewsInfoscreen } from "./news-infoscreen";
import { initializePlausible } from "./analytics";
import { EventList } from "./vue/event-list";
import { NewsList } from "./vue/news-list";
import { SubjectCoverSearch } from "~/vue/subject-cover-search";
import { KbCoverSearch } from "~/vue/kb-cover-search";
import { SvgIcon } from "~/vue/svg-icon";
import { TeamCoverSearch } from "~/vue/team-cover-search";
import { PersonDetail } from "~/vue/person-detail";
import { TeamFilter } from "~/vue/team-filter";

ConfigProgrammatic.setOptions({
	defaultIconComponent: SvgIcon as any,
	defaultIconPack: "icon",
});

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.component("svg-icon", SvgIcon);

			app.use(Field);
			app.use(Input);
			app.use(Checkbox);
			app.use(Radio);
			app.use(Button);
			app.use(Select);
			app.use(Loading);
			app.use(Notification);
			app.use(Modal);
			app.use(Snackbar);
			app.use(Autocomplete);

			app.config.globalProperties.$formatters = {
				humanizeFilesize: (valueInBytes: number) => humanize.fileSize(valueInBytes),
				date: (value: string, format: string) => formatDate(value, format, { locale: deLocale })
			};
		}
	});
}

customElements.define("news-list", defineAndConfigureCustomElement(NewsList));
customElements.define("event-list", defineAndConfigureCustomElement(EventList));
customElements.define("subject-cover-search", defineAndConfigureCustomElement(SubjectCoverSearch));
customElements.define("kb-cover-search", defineAndConfigureCustomElement(KbCoverSearch));

if (document.querySelector("kb-category") != null) {
	import("./vue/kb-category").then(m => {
		customElements.define("kb-category", defineAndConfigureCustomElement(m.KbCategory));
	});
}
if (document.querySelector("form-trigger") != null) {
	import("./vue/form-trigger").then(m => {
		customElements.define("form-trigger", defineAndConfigureCustomElement(m.FormTrigger));
	});
}

customElements.define("team-cover-search", defineAndConfigureCustomElement(TeamCoverSearch));
customElements.define("team-filter", defineAndConfigureCustomElement(TeamFilter));

$(".team-cover-image").on("click", (e) => {
	var image = $(e.target);
	PersonDetail.open(image.data("personId"));
});

new Events();
new Cover();
new Site();
new NewsInfoscreen();

initializeMaps();
initializePlausible();
