<div class="cover-search search-container" :class="classes">
	<b-autocomplete class="input-search" expanded size="is-large" icon="search" :placeholder="placeholder" :loading="isLoading" :data="results" @typing="searchDebounced" @select="onSelect">
		<template v-slot="props">
			<slot :item="props.option"></slot>
		</template>
		<template #empty>
			<slot name="empty"></slot>
		</template>
	</b-autocomplete>
</div>
