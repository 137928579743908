import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";

import { PersonDetail } from "./person-detail";
import { CoverSearch } from "./cover-search";
import type { Person } from "../models/Person";
import { render } from "./team-cover-search.html";


@Component({
render,
	components: {
		CoverSearch
	}
})
export class TeamCoverSearch extends Vue {
	onSelect(item: Person) {
		PersonDetail.open(item.id);
	}
}
