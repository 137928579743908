<div class="mb-4">
	<slide-y-up-transition>
		<div class="news-items clearfix" ref="flickity">
			<div class="news-item" v-for="item in news">
				<template v-if="item.kind == 'news'">
					<h3 class="news-item-title">{{item.title}}</h3>
					<img :src="`${item.imageUrl}?width=250&height=200`" v-if="item.imageUrl != null" />
					<div class="news-item-snippet hyphenate" v-html="item.snippetHtml" />
					<button class="button is-small is-primary news-item-continuereading" @click="openItem(item)" v-if="getIsSnippetShortened(item)">Weiterlesen</button>
				</template>
				<template v-if="item.kind == 'event'">
					<h3 class="news-item-title">
						<span class="event-title-date">{{$formatters.date(item.dateTime, 'D. MMMM YYYY HH:mm')}}</span>
						<span>{{item.title}}</span>
					</h3>
					<div class="news-item-snippet hyphenate">
						<p><em>{{item.location}}</em></p>
						<p>{{item.textHtml}}</p>
					</div>
					<a class="button is-small is-primary news-item-continuereading" :href="item.link" target="getHasExternalLink(item) ? '_blank' : '_self'" v-if="item.link && item.link.length">Mehr Informationen</a>
				</template>
			</div>
		</div>
	</slide-y-up-transition>
</div>
