import Plyr from "plyr";

const ActiveClass = "is-active";

let standalonePlayers = Plyr.setup("audio:not(.plyr--playlist)");
Array.from(document.querySelectorAll(".plyr--playlist")).forEach(element => {
	let playlistId = element.getAttribute("data-playlist-id");
	if (playlistId == null) {
		return;
	}

	let playlist = document.querySelector(playlistId);
	if (playlist == null) {
		return;
	}

	setupPlaylistPlayer(<HTMLElement>element, playlist);
});

function setupPlaylistPlayer(element: HTMLElement, playlist: Element) {
	let player = new Plyr(element);
	let tracks = Array.from(document.querySelectorAll(`#${playlist.id} li`));
	if (tracks.length === 0) {
		return;
	}

	loadTrack(tracks[0]);

	player.on("ended", () => {
		let activeIndex = tracks.findIndex(e => e.classList.contains(ActiveClass));
		if (activeIndex < tracks.length - 1) {
			loadTrack(tracks[activeIndex + 1]);
			player.play();
		}
	});

	playlist.addEventListener("click", e => {
		let target = (<HTMLElement>e.target);
		if (target.closest(`#${playlist.id}`) != null && target.closest(".download") == null) {
			let track = target.closest(".plyr__track");
			if (track != null) {
				if (track.classList.contains(ActiveClass)) {
					player.togglePlay();
				} else {
					loadTrack(track);
					player.play();
				}
				e.preventDefault();
			}
		}
	});

	function loadTrack(trackElement: Element) {
		let src = trackElement.getAttribute("data-src");
		let type = trackElement.getAttribute("data-type");

		if (src == null || type == null) {
			return;
		}

		tracks.forEach(e => e.classList.remove(ActiveClass));

		player.source = {
			type: "audio",
			title: trackElement.textContent!,
			sources: [{ type, src }]
		};

		trackElement.classList.add(ActiveClass);
	}
}
