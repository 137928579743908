import { Vue, Component, Prop, Emit } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./subject-cover-search.html";
import { CoverSearch } from "./cover-search";
import type { Subject } from "../models/Subjects";

@Component({
render,
	components: {
		CoverSearch
	}
})
export class SubjectCoverSearch extends Vue {
	onSelect(item: Subject) {
		window.location.href = item.url;
	}
}
