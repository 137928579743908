<cover-search class="team-search" url="/api/persons?q=%QUERY" placeholder="Name, Funktion, Fach oder Instrument…" @select="onSelect">
	<template v-slot="{item}">
		<div class="aa-suggestion">
			<img :src="`${item.imageUrl}?width=100&height=100`" />
			<h5>{{item.firstname}} {{item.lastname}}</h5>
			<div class="functions">
				<template v-for="f in item.functions">
					{{f.description}}
					<br />
				</template>
			</div>
		</div>
	</template>
	<template #empty>
		<p>Keine Personen gefunden.</p>
	</template>
</cover-search>
