import Packery from "packery";
import Flickity from "flickity";
import enquire from "enquire.js";
import imagesLoaded from "imagesloaded";
import $ from "jquery";
import { Fancybox } from "@fancyapps/ui";

import { mediaQueries } from "./utils";

export class Events {
	constructor() {
		if (document.body.classList.contains("event-overview")) {
			this.setupEventOverview();
		}

		if (document.body.classList.contains("event-detail")) {
			this.setupEventDetail();
		}
	}

	setupEventOverview() {
		let flickity: Flickity | null = null;

		enquire.register(mediaQueries.timeline, {
			match: () => {
				var eventElements = $(".timeline-event");
				var timeline = $("#timeline");

				eventElements.each(function (index, el) {
					$(el).css({ "transition-delay": `${index * 200}ms` });
				});

				window.setTimeout(() => {
					timeline.addClass("is-visible");
					eventElements.addClass("is-visible");
				}, 100);

				flickity = new Flickity($(".timeline-event-list")[0], {
					cellSelector: ".timeline-event",
					cellAlign: "left",
					contain: true,
					setGallerySize: false,
					freeScroll: true,
					prevNextButtons: true,
					pageDots: false
				});

				var todayIndex = eventElements.index(eventElements.filter(".today"));
				window.setTimeout(() => {
					if (flickity != null) {
						flickity.select(Math.max(todayIndex - 1, 0));
					}
				}, todayIndex * 200);

				document.body.scrollTo({ top: 0, behavior: "smooth" });
			},
			unmatch: () => {
				if (flickity != null) {
					flickity.destroy();
				}

				$(".timeline-event").css({ opacity: 1 });
			}
		});
		enquire.register("screen and (max-width: 30em)", () => {
			document.querySelector(".today")?.scrollIntoView({ behavior: "smooth" });
		});
	}

	setupEventDetail() {
		var galleryContent = $(".event-gallery-content");
		if (galleryContent.length) {
			imagesLoaded(galleryContent[0], function () {
				var packery = new Packery(galleryContent[0], {
					itemSelector: ".event-gallery-item",
					columnWidth: ".grid-sizer"
				});
			});

			Fancybox.bind(".event-gallery-item");
		}
	}
}
